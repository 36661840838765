import React from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { addHotspot } from '../utils/imageUrl';
import { defaultSerializers } from '../utils/serializers';
import formatRFC7231 from 'date-fns/formatRFC7231';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {
  FaArrowRight,
  FaLongArrowAltLeft,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
} from 'react-icons/fa';
import truncate from 'lodash/truncate';
import toPlainText from '../utils/toPlainText';
import { stringify } from 'query-string';

function JournalShare({ title, slug, box = false }) {
  return (
    <aside
      style={{
        opacity: box ? 1 : 0.7,
        fontWeight: 'bold',
        padding: box ? '1em' : 0,
        borderRadius: '6px',
        background: box ? '#fff' : 'transparent',
      }}
    >
      Share{box ? ' this journal entry on' : ''}:{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Twitter"
        target="_blank"
        rel="noopener"
        href={`https://twitter.com/intent/tweet?${stringify({
          text: `"${title}" by @nicolettemmusic \n\n https://nicolette.me/journal/${slug}/`,
        })}`}
      >
        <FaTwitter size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share on Facebook"
        target="_blank"
        rel="noopener"
        href={`https://www.facebook.com/sharer/sharer.php?${stringify({
          u: `https://nicolette.me/journal/${slug}/`,
        })}`}
      >
        <FaFacebook size="1.5em" color="#444" />
      </a>{' '}
      <a
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: '0.5em',
        }}
        title="Share by Email"
        href={`mailto:?${stringify({
          subject: title,
          body: `"${title}" by Nicolette Macleod\n\nhttps://nicolette.me/journal/${slug}/\n\n `,
        })}`}
      >
        <FaEnvelope size="1.5em" color="#444" />
      </a>{' '}
    </aside>
  );
}

function JournalEntry(props) {
  const { data, pageContext } = props;
  const { currentEntry, previousEntry, nextEntry } = data;
  const {
    mainImage,
    title,
    date,
    slug,
    _rawContent,
    videoShare,
  } = currentEntry;

  return (
    <Layout
      headerImage={
        mainImage
          ? addHotspot(mainImage.asset.fixed.src, mainImage.hotspot)
          : undefined
      }
      dominantColour={mainImage.asset.metadata.palette.dominant.background}
      textColour={mainImage.asset.metadata.palette.dominant.title}
    >
      <SEO
        title={`${title} - Nicolette Macleod - Journal`}
        image={
          mainImage
            ? addHotspot(mainImage.asset.fixed.src, mainImage.hotspot)
            : undefined
        }
        twitterCard={videoShare ? 'player' : 'summary_large_image'}
        twitterPlayerWidth={560}
        twitterPlayerHeight={315}
        meta={
          videoShare
            ? [{ name: 'twitter:player', content: videoShare }]
            : undefined
        }
      />
      <article className="page-content page-content--journal h-entry">
        <header className="journal__header">
          <Link className="journal__back-link" to="/journal">
            journal /
          </Link>

          <h1 className="journal__title p-name">
            <Link className="u-url" to={`/journal/${slug.current}/`}>
              {title}
            </Link>
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyItems: 'center',
              marginTop: '1.5em',
            }}
          >
            <h2 className="journal__date" style={{ flexGrow: 1 }}>
              Posted{' '}
              <time
                className="dt-published"
                dateTime={date}
                title={formatRFC7231(Date.parse(date))}
              >
                {formatDistanceToNow(Date.parse(date), { addSuffix: true })}
              </time>
            </h2>
            <JournalShare slug={slug.current} title={title} />
          </div>
        </header>
        <section className="journal__content e-content">
          <BlockContent blocks={_rawContent} serializers={defaultSerializers} />
          <JournalShare slug={slug.current} title={title} box />
        </section>
        <aside
          style={{
            borderTop: '1px solid #ddd',
            marginTop: '2em',
            paddingTop: '3em',
          }}
        >
          {Boolean(previousEntry) && (
            <>
              <h4>Older entry:</h4>

              <div
                className="journal__list__entry"
                key={previousEntry.id}
                style={{
                  backgroundImage: `url(${addHotspot(
                    previousEntry.mainImage.asset.fixed.src,
                    previousEntry.mainImage.asset.hotspot
                  )})`,
                }}
              >
                <Link
                  to={`/journal/${previousEntry.slug.current}/`}
                  className="journal-link"
                >
                  <h2 className="journal__title">{previousEntry.title}</h2>
                  <p>
                    {truncate(toPlainText(previousEntry._rawContent), {
                      length: 160,
                    })}{' '}
                    <span className="read-more-link">Read&nbsp;more</span>
                  </p>
                  <span className="journal__date">
                    Posted{' '}
                    <time
                      className="dt-published"
                      dateTime={previousEntry.date}
                      title={formatRFC7231(Date.parse(previousEntry.date))}
                    >
                      {formatDistanceToNow(Date.parse(previousEntry.date), {
                        addSuffix: true,
                      })}
                    </time>
                  </span>
                </Link>
              </div>
            </>
          )}
          {Boolean(nextEntry) && (
            <>
              <h4>Next entry:</h4>

              <div
                className="journal__list__entry"
                key={nextEntry.id}
                style={{
                  backgroundImage: `url(${addHotspot(
                    nextEntry.mainImage.asset.fixed.src,
                    nextEntry.mainImage.asset.hotspot
                  )})`,
                }}
              >
                <Link
                  to={`/journal/${nextEntry.slug.current}/`}
                  className="journal-link"
                >
                  <h2 className="journal__title">{nextEntry.title}</h2>
                  <p>
                    {truncate(toPlainText(nextEntry._rawContent), {
                      length: 160,
                    })}{' '}
                    <span className="read-more-link">Read&nbsp;more</span>
                  </p>
                  <span className="journal__date">
                    Posted{' '}
                    <time
                      className="dt-published"
                      dateTime={nextEntry.date}
                      title={formatRFC7231(Date.parse(nextEntry.date))}
                    >
                      {formatDistanceToNow(Date.parse(nextEntry.date), {
                        addSuffix: true,
                      })}
                    </time>
                  </span>
                </Link>
              </div>
            </>
          )}
        </aside>
      </article>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($previous: String!, $next: String!, $slug: String!) {
    previousEntry: sanityJournal(slug: { current: { eq: $previous } }) {
      id
      slug {
        current
      }
      title
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fixed(width: 150, height: 350) {
            ...GatsbySanityImageFixed
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 1 })
      date
    }
    nextEntry: sanityJournal(slug: { current: { eq: $next } }) {
      id
      slug {
        current
      }
      title
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fixed(width: 150, height: 350) {
            ...GatsbySanityImageFixed
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 1 })
      date
    }
    currentEntry: sanityJournal(slug: { current: { eq: $slug } }) {
      id
      title
      date
      videoShare
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              dominant {
                background
                title
              }
            }
          }
          fixed(width: 2000, height: 1000, toFormat: JPG) {
            ...GatsbySanityImageFixed
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

export default JournalEntry;
